import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginGuard } from './login.guard';
import { NoLoginGuard } from './no-login.guard';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule} from './demo-material-module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { BackendService } from './service/backend.service';

import { MatInputModule, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';

//Interceptor
import { MatDialogModule } from '@angular/material';
import { ErrorDialogComponent } from './error-dialog/errordialog.component';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { HttpConfigInterceptor } from './_helpers/jwt.interceptor';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
  AppComponent,
  FullComponent,
  AppHeaderComponent,
  SpinnerComponent,
  AppBlankComponent,
  AppSidebarComponent,
  ErrorDialogComponent  
  ],
  imports: [
  BrowserModule,
  BrowserAnimationsModule,
  DemoMaterialModule,
  FormsModule,
  FlexLayoutModule,
  HttpClientModule,
  PerfectScrollbarModule,
  SharedModule,
  RouterModule.forRoot(AppRoutes),
  NgbModule,
  MatDatepickerModule,
  MatNativeDateModule,
  ],
  exports: [
  MatDatepickerModule,
  MatNativeDateModule,
  ],
  providers: [
  {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }, {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  LoginGuard,
  NoLoginGuard,
  BackendService,
  {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ErrorDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  entryComponents: [ErrorDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
