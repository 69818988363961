import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}
/*
*class: 'hide-submenu'
*este parametro nos ayuda a agregar una opcion al menu de forma oculta 
*y asi poder utilizar la ruta en el panel de contenido y al dar clic el menu quede como activo
*/
const MENUITEMS = [


{
  state: 'home',
  name: 'Home',
  type: 'link',
  icon: 'home',
},
{
  state: 'reportes',
  name: 'Reportes',
  type: 'link',
  icon: 'assignment',
},
{
  state: 'catalogos',
  name: 'Catálogos',
  type: 'sub',
  icon: 'apps',
  children: [
    {state: 'actividades', name: 'Actividades'},
    {state: 'actividades/agregar', name: '', class: 'hide-submenu', },
    {state: 'almacenes', name: 'Almacenes'},
    {state: 'almacenes/agregar', name: '', class: 'hide-submenu', },
    {state: 'cultivos', name: 'Cultivos'},
    {state: 'cultivos/agregar', name: '', class: 'hide-submenu', },
    {state: 'huertas', name: 'Huertas'},
    {state: 'huertas/agregar', name: '', class: 'hide-submenu', },
    {state: 'precios', name: 'Precios'},
    {state: 'precios/agregar', name: '', class: 'hide-submenu', },
    {state: 'productos', name: 'Productos'},
    {state: 'productos/agregar', name: '', class: 'hide-submenu', },
    {state: 'recursos', name: 'Recursos'},
    {state: 'recursos/agregar', name: '', class: 'hide-submenu', },
    {state: 'servicios', name: 'Servicios'},
    {state: 'servicios/agregar', name: '', class: 'hide-submenu', },
    {state: 'ubicaciones', name: 'Ubicaciones'},
    {state: 'ubicaciones/agregar', name: '', class: 'hide-submenu', },
    {state: 'usuarios', name: 'Usuarios'},
    {state: 'usuarios/agregar', name: '', class: 'hide-submenu', },
  ]
},
{
  state: 'inventario',
  name: 'Inventario',
  type: 'sub',
  icon: 'book',
  children: [
    {state: 'saldos', name: 'Saldos'},
    {state: 'historial', name: 'Historial'},
    {state: 'entradas', name: 'Registrar entradas'},
    {state: 'salidas', name: 'Registrar salidas'},
  ]
},
{
  state: 'operaciones',
  name: 'Operaciones',
  type: 'sub',
  icon: 'archive',
  children: [
    {state: 'produccion', name: 'Registrar producción'},
    {state: 'labor', name: 'Registrar labor'},
    {state: 'entrega', name: 'Registrar entrega'},
  ]
},
{
  state: 'gastos',
  name: 'Gastos',
  type: 'sub',
  icon: 'money',
  children: [
    {state: 'historial', name: 'Historial'},
    {state: 'agregar', name: 'Registrar gasto'}
  ]
},
{
  state: 'evoluciones',
  name: 'Evoluciones',
  type: 'link',
  icon: 'local_florist',
}
];

@Injectable()

export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }

}
