import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BackendService } from '../../../service/backend.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  isVisible = false;

  constructor ( public rest:BackendService){}    
  
  logOut(){
    this.isVisible = true;
    this.rest.beCerrarSession();
  }
}
