import { Injectable, isDevMode   } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

const apiEndpoint: string = environment.apiEndpoint;
const apiEndpointFiles: string = environment.apiEndpointFiles;

@Injectable()
export class BackendService {
  constructor(private http: HttpClient, private router: Router) { }
  /* 
  *Servicios basicos
  *Login
  */
  beIniciarSession(login){

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

      this.http.post(apiEndpoint+'Sesion:iniciar', login, httpOptions).subscribe(
        data => 
        {
          localStorage.setItem('JWT', data['data'].jwt);
          let usuario_id = { usuario_id : data['data'].payload.sub };
          this.beObtenerUsuario(usuario_id).subscribe(
            item => 
            {
              localStorage.setItem('usuario_id', item['data'].usuario.id);
              localStorage.setItem('nombre', item['data'].usuario.nombre);
              localStorage.setItem('correo', item['data'].usuario.correo);
              localStorage.setItem('perfiles', JSON.stringify(item['data'].usuario.perfiles));
              this.router.navigate ( [ '/home' ] );
              window.location.reload();
            });
        },
        (err) => 
        {
          this.obtener_mensajes_error(err);
        });
  }

  beObtenerApyKey(formLogin){
    let params =
    {
      correo : formLogin.correo,
      apikey : '',
    };
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.http.post(apiEndpoint+'Usuarios:obtener_apikey', formLogin, httpOptions).subscribe(
      result => 
      {
        params.apikey = result['data'].apikey;
        this.beIniciarSession(params)
      },
      (err) => 
      {
        this.obtener_mensajes_error(err);
      });
  }

  beCerrarSession(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };

    let data = {};
    return this.http.post(apiEndpoint+"sesion:cerrar", data, httpOptions).subscribe(
      result => 
      {
        localStorage.clear()
        this.router.navigate(['/authentication/login']);
      },
      (err) => 
      {
        localStorage.clear()
        this.router.navigate(['/authentication/login']);
      }
      )
  }

  beLimpiarLs(){
    localStorage.clear()
    this.router.navigate(['/authentication/login']);
  }

  beRecuperarContrasena(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this.http.post(apiEndpoint+"Usuarios:recuperar_contrasena", formData, httpOptions)
  }

  crearContrasena(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    this.http.post(apiEndpoint+"Usuarios:modificar_contrasena_recuperar", formData, httpOptions).subscribe(() => {
      // this.alert.showNotification('top', 'right', 'La cuenta fue confirmada con éxito.', 1)
      alert('La cuenta fue confirmada con éxito.')
      this.router.navigate(['/authentication/login'])
    },
      (err) => {
        console.log(err);
        this.obtener_mensajes_error(err);
        // this.alert.showNotification('top', 'right', 'Verifique sus datos porfavor.', 4)
      })
  }

  /*
  *Usuarios
  */
  beConsultarUsuarios(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };

    return this.http.post(apiEndpoint+"Usuarios:consultar", filtros, httpOptions);
  }

  beAgregarUsuario(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Usuarios:agregar", formData, httpOptions)
  }

  beObtenerUsuario(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Usuarios:obtener", formData, httpOptions)
  }

  beModificarUsuario(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Usuarios:modificar", formData, httpOptions)
  }

  beModificarContrasena(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Usuarios:modificarcontrasena", formData, httpOptions)
  }

  beEliminarUsuario(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Usuarios:eliminar", formData, httpOptions)
  }

  /*
  *Utilidades
  */
  obtener_mensajes_error(err)
  {
    if ( err.error.message == 'The given data was invalid.') 
    {
      var mensaje_error = '';  
      for(let element in err.error.errors) 
      {   
        mensaje_error += err.error.errors[element][0]+"\r\n";
      }
      alert(mensaje_error)
    }
    else
    {
      alert(err.error.message)
    }
  }
  
  setFormatDate(date)
  {
    let d = new Date(date);
    let mm = d.getMonth() + 1;
    let dd = d.getDate();
    let yy = d.getFullYear();
    return (yy + '-' + mm + '-' + dd); 
  }


  /*
  *cultivos
  */
  beConsultarCultivos(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Cultivos:consultar", filtros, httpOptions);
  }

  beAgregarCultivo(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Cultivos:agregar", formData, httpOptions)
  }

  beObtenerCultivo(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Cultivos:obtener", formData, httpOptions)
  }

  beModificarCultivo(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Cultivos:modificar", formData, httpOptions)
  }

  beEliminarCultivo(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Cultivos:eliminar", formData, httpOptions)
  }

  /*
  *ubicaciones
  */
  beConsultarUbicaciones(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Ubicaciones:consultar", filtros, httpOptions);
  }

  beConsultarUbicaciones_Consultar(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Ubicaciones:consultar_ubicaciones", filtros, httpOptions);
  }

  beAgregarUbicacion(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Ubicaciones:agregar", formData, httpOptions)
  }

  beObtenerUbicacion(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Ubicaciones:obtener", formData, httpOptions)
  }

  beModificarUbicacion(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Ubicaciones:modificar", formData, httpOptions)
  }

  beEliminarUbicacion(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Ubicaciones:eliminar", formData, httpOptions)
  }

  /*
  *actividades
  */
  beConsultarActividades(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Actividades:consultar", filtros, httpOptions);
  }

  beAgregarActividad(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Actividades:agregar", formData, httpOptions)
  }

  beObtenerActividad(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Actividades:obtener", formData, httpOptions)
  }

  beModificarActividad(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Actividades:modificar", formData, httpOptions)
  }

  beEliminarActividad(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Actividades:eliminar", formData, httpOptions)
  }
  
  /*
  *recursos
  */
  beConsultarRecursos(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Recursos:consultar", filtros, httpOptions);
  }

  beAgregarRecurso(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Recursos:agregar", formData, httpOptions)
  }

  beObtenerRecurso(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Recursos:obtener", formData, httpOptions)
  }

  beModificarRecurso(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Recursos:modificar", formData, httpOptions)
  }

  beEliminarRecurso(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Recursos:eliminar", formData, httpOptions)
  }

  /*
  *huertas
  */
  beConsultarHuertas(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Huertas_productores:consultar", filtros, httpOptions);
  }

  beAgregarHuerta(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"huertas_productores:agregar", formData, httpOptions)
  }

  beObtenerHuerta(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Huertas_productores:obtener", formData, httpOptions)
  }

  beModificarHuerta(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Huertas_productores:modificar", formData, httpOptions)
  }

  beEliminarHuerta(formData){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Huertas_productores:eliminar", formData, httpOptions)
  }


  /*
  *productos
  */
  beConsultarProductos(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Productos:consultar", filtros, httpOptions);
  }

  beAgregarProducto(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Productos:agregar", formData, httpOptions)
  }

  beObtenerProducto(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Productos:obtener", formData, httpOptions)
  }

  beModificarProducto(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Productos:modificar", formData, httpOptions)
  }

  beEliminarProducto(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Productos:eliminar", formData, httpOptions)
  }




  /*
  *almacenes
  */
  beConsultarAlmacenes(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };

    let data = {}; 
    return this.http.post(apiEndpoint+"Almacenes:consultar", filtros, httpOptions);
  }

  beAgregarAlmacen(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Almacenes:agregar", formData, httpOptions)
  }

  beObtenerAlmacen(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Almacenes:obtener", formData, httpOptions)
  }

  beModificarAlmacen(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Almacenes:modificar", formData, httpOptions)
  }

  beEliminarAlmacen(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Almacenes:eliminar", formData, httpOptions)
  }

  /*
  *kardex
  */
  beConsultarKardex(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Inventario:consultarKardex", filtros, httpOptions);
  }
  /*
  *saldos
  */
  beConsultarSaldos(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Inventario:consultar_saldos", filtros, httpOptions);
  }
  /*
  *historial
  */
  beConsultarHistorial(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Inventario:consultar_documentos", filtros, httpOptions);
  }

  beObtenerDocumento(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"inventario:obtener_documento", filtros, httpOptions);
  }
  

  /*
  *entrdas
  */
  beAgregarEntrada(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"documentos:entrada", formData, httpOptions)
  }

  beCancelarEntrada(formData) {
    let httpOptions =
    {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint + "documentos:entrada_cancelar", formData, httpOptions)
  }
  /*
  *salidas
  */

  beAgregarSalida(formData)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"Documentos:salida", formData, httpOptions)
  }

  /*
  *dashboard
  */
  beConsultarTotalesRegion(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:produccion_totales_por_region", filtros, httpOptions);
  }

  

  beConsultarCostosRegion(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:costos_totales_por_region", filtros, httpOptions);
  } 

  beConsultarTotalesHuerta(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:produccion_totales_por_huerta", filtros, httpOptions);
  }

  beConsultarCostosHuerta(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:costos_totales_por_huerta", filtros, httpOptions);
  }

  beConsultarCantidadesSector(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:produccion_totales_por_sector", filtros, httpOptions);
  }

  beConsultarCostosSector(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:costos_totales_por_sector", filtros, httpOptions);
  }

  beConsultarTotalesActividad(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:costos_totales_por_actividad", filtros, httpOptions);
  }

  beConsultarDesgloseActividades(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:costos_desglose_de_actividades", filtros, httpOptions);
  }

  beConsultarDesgloseElementos(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:desglose_de_elementos", filtros, httpOptions);
  }

  beConsultarActividadesSector(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:costos_totales_por_actividad", filtros, httpOptions)
  }

  beConsultarElementosSector(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:desglose_de_elementos", filtros, httpOptions)
  }

  beConsultarDesgloseActividadesSector(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpoint+"dashboard:costos_desglose_de_actividades", filtros, httpOptions)
  }


  beImprimirGafete(filtros)
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    };
    return this.http.post(apiEndpointFiles+"farming:harvest52/tickets:imprimir_gafete", filtros, httpOptions)
  }

  /*Servicios */
  consultarServicios()
  {
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }
    let body = {}
    return this.http.post(apiEndpoint+"servicios:consultar", body, httpOptions);
  }

  obtenerServicio(id)
  {   
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }
    let body = {}
    return this.http.post(apiEndpoint+"servicios:obtener", id, httpOptions);
  }

  agregarServicio(servicio){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"servicios:agregar", servicio, httpOptions);
  }

  modificarServicio(servicio){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"servicios:modificar", servicio, httpOptions);
  }

  eliminarServicio(servicio){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"servicios:eliminar", servicio, httpOptions);
  }

  /*Precios */
  consultarPrecios(){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }
    let body = {}

    return this.http.post(apiEndpoint+"precios:consultar", body, httpOptions);
  }

  obtenerPrecio(precio){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"precios:obtener", precio, httpOptions);
  }

  agregarPrecio(precio){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"precios:agregar", precio, httpOptions);
  }

  modificarPrecio(precio){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"precios:modificar", precio, httpOptions);
  }

  eliminarPrecio(precio){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"precios:eliminar", precio, httpOptions);
  }

  /*Gastos */
  consultarGasto(filtros){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"documentos:gasto_consultar", filtros, httpOptions);
  }

  obtenerGasto(id){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"documentos:gasto_obtener", id, httpOptions);
  }

  agregarGasto(gasto){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"documentos:gasto", gasto, httpOptions);
  }

  cancelarGasto(gasto){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpoint+"documentos:gasto_cancelar", gasto, httpOptions);
  }

  /*Evoluciones */

  evolucionesConsultar(body = {}){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }
    
    return this.http.post(apiEndpoint+"evoluciones:consultar", body, httpOptions);
  }

  evolucionesObtener(evolucion){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }
    let body = {}

    return this.http.post(apiEndpoint+"evoluciones:obtener", evolucion, httpOptions);
  }

  obtenerFotografias(evolucion){
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }
    let body = {}

    return this.http.post(apiEndpoint+"evoluciones:obtener_fotografias", evolucion, httpOptions);
  }

  //reportes
  consultarReportes(){
    
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }
    let body = {}

    return this.http.post(apiEndpointFiles+"reports:la_mora_escondida/jasper:consultar", body, httpOptions);
  }

  generarReportes(body){
    
    let httpOptions = 
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('JWT')
      })
    }

    return this.http.post(apiEndpointFiles+"reports:la_mora_escondida/jasper:ejecutar", body, httpOptions);
  }

    //produccion
    consultarProduccion(body) {

      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
      // let body = {}
  
      return this.http.post(`${apiEndpoint}documentos:produccion_consultar`, body, httpOptions);
    }
  
    agregarProduccion(produccion) {
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
  
      return this.http.post(apiEndpoint + "documentos:produccion", produccion, httpOptions);
    }
  
    cancelarProduccion(produccion) {
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
  
      return this.http.post(apiEndpoint + "documentos:produccion_cancelar", produccion, httpOptions);
    }
  
    //labor
    consultarLabor(body) {
  
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
  
      return this.http.post(`${apiEndpoint}documentos:labor_consultar`, body, httpOptions);
    }
  
    agregarLabor(labor) {
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
  
      return this.http.post(`${apiEndpoint}documentos:labor`, labor, httpOptions);
    }
  
    cancelarLabor(labor) {
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
  
      return this.http.post(apiEndpoint + "documentos:labor_cancelar", labor, httpOptions);
    }
  
    //entrega
    consultarEntrega(body) {
  
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
  
      return this.http.post(`${apiEndpoint}documentos:entrega_consultar`, body, httpOptions);
    }
  
    agregarEntrega(entrega) {
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      }
  
      return this.http.post(apiEndpoint + "documentos:entrega", entrega, httpOptions);
    }

    beCancelarEntrega(formData) {
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      };
      return this.http.post(apiEndpoint + "documentos:entrega_cancelar", formData, httpOptions)
    }

    beCancelarSalida(formData) {
      let httpOptions =
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('JWT')
        })
      };
      return this.http.post(apiEndpoint + "documentos:salida_cancelar", formData, httpOptions)
    }
  
}
