import { Routes } from '@angular/router';
import { LoginGuard } from './login.guard';
import { NoLoginGuard } from './no-login.guard';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

export const AppRoutes: Routes = [{
  path: '',
  component: FullComponent,
  children: [{
    path: '',
    redirectTo: '/authentication/login',
    pathMatch: 'full'
  }, 
  {
    path: 'home',
    loadChildren: './dashboards/dashboards.module#DashboardsModule'
  }, 
  {
    path: 'catalogos',
    loadChildren: './catalogos/catalogos.module#CatalogosModule'
  },
  {
    path: 'gastos',
    loadChildren: './gastos/gastos.module#GastosModule'
  },
  {
    path: 'inventario',
    loadChildren: './inventario/inventario.module#InventarioModule'
  },
  {
    path: 'operaciones',
    loadChildren: './operaciones/operaciones.module#OperacionesModule'
  },
  {
    path: 'perfil',
    loadChildren: './perfil/perfil.module#PerfilModule'
  },
  {
    path: 'evoluciones',
    loadChildren: './evoluciones/evoluciones.module#EvolucionesModule'
  },
  {
    path: 'reportes',
    loadChildren: './reportes/reportes.module#ReportesModule'
  },
  ],
  canActivate:[LoginGuard],

}, 
{
  path: '',
  component: AppBlankComponent,
  children: [{
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }],
  canActivate:[NoLoginGuard],
  
}, 
{
  path: '**',
  redirectTo: 'authentication/404'
}];
