import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorDialogComponent } from './errordialog.component';
import { BackendService } from '../service/backend.service';

@Injectable()
export class ErrorDialogService {

    constructor(public dialog: MatDialog, private back: BackendService) { }
    openDialog(data): void {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '300px',
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
           
        });
    }
}
